import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  useConnection,
  useConnectionState,
  useLoginCallback,
} from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import _ from 'lodash';

import {
  LanguageToggle,
  Logo,
  LogoTitle,
  useIsConnected,
} from 'ecarepd-shared-utilities';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PATH_SETTINGS } from '../content/constants';

const OIDC_PATH =
  process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;

function HeaderMenu({
  anchorEl,
  onClose,
}: {
  anchorEl: Element | null;
  onClose: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const connectionState = useConnectionState();

  const handleSettings = useCallback(() => {
    navigate(PATH_SETTINGS);
  }, [navigate]);

  const handleLogin = useLoginCallback(OIDC_PATH, onClose);

  const handleLogout = useCallback(() => {
    ConnectionService.releaseBee();
    onClose();
  }, [onClose]);

  const items = useMemo(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return [
          <MenuItem key="sign-in" onClick={handleLogin}>
            <>{t('header.login')}</>
          </MenuItem>,
        ];

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return [
          <MenuItem key="settings" onClick={handleSettings}>
            <>{t('header.settings')}</>
          </MenuItem>,
          <MenuItem key="sign-out" onClick={handleLogout}>
            <>{t('header.logout')}</>
          </MenuItem>,
        ];
    }

    return [];
  }, [connectionState, t, handleLogin, handleLogout, handleSettings]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!anchorEl}
      onClose={onClose}
    >
      {items}
    </Menu>
  );
}

function MenuAndLanguage(): JSX.Element {
  const connection = useConnection();
  const username = _.get(connection, 'username', '');
  const isConnected = useIsConnected();

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const handleOpenMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  if (!isConnected) {
    return <LanguageToggle />;
  }

  return (
    <>
      <HeaderMenu anchorEl={menuAnchor} onClose={handleCloseMenu} />

      <Stack direction="column" spacing={0}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleOpenMenu}
        >
          <Stack direction="row" spacing={1}>
            <Typography>{username}</Typography>
            <MenuIcon />
          </Stack>
        </IconButton>

        <LanguageToggle />
      </Stack>
    </>
  );
}

export default function Header(): JSX.Element {
  return (
    <header>
      <Paper>
        <AppBar position="static">
          <Toolbar>
            <RouterLink to="/">
              <Logo />
              <LogoTitle />
            </RouterLink>

            <Container />

            <MenuAndLanguage />
          </Toolbar>
        </AppBar>
      </Paper>
    </header>
  );
}
