import { Stack } from '@mui/material';
import {
  PatientPriorityResponse,
  SymptomPriority,
  useWindowSize,
} from 'ecarepd-shared-utilities';
import {
  createRef,
  Fragment,
  PropsWithRef,
  useEffect,
  useMemo,
  useState,
} from 'react';

import _ from 'lodash';
import { CarePriorityScaleItem } from './CarePriorityScaleItem';

export interface CarePriorityScaleProps {
  responses: PatientPriorityResponse[];
  symptomPriority: SymptomPriority;
}
export function CarePriorityScale({
  responses,
  symptomPriority,
}: PropsWithRef<CarePriorityScaleProps>): JSX.Element {
  const processed = useMemo(
    () =>
      _.filter(
        responses,
        (s: PatientPriorityResponse) =>
          s.properties.priorityID === symptomPriority.id &&
          s.properties.response >= 0
      ),
    [responses, symptomPriority]
  );

  const { width: windowWidth } = useWindowSize();

  const parentRef = createRef<HTMLElement>();
  const contentRef = createRef<HTMLElement>();
  const [justifyContent, setJustifyContent] = useState<string | undefined>();
  useEffect(() => {
    const parentWidth = parentRef?.current?.offsetWidth;
    const contentWidth = contentRef?.current?.offsetWidth;
    const newJustifyContent =
      parentWidth && contentWidth && parentWidth > contentWidth
        ? 'center'
        : undefined;
    setJustifyContent(newJustifyContent);
  }, [parentRef, contentRef, windowWidth]);

  return (
    <Stack direction="row" ref={parentRef} justifyContent={justifyContent}>
      <Stack direction="row" spacing={16} ref={contentRef} overflow="auto">
        {_.map(processed, (response: PatientPriorityResponse) => (
          <Fragment key={response.id}>
            <CarePriorityScaleItem
              symptomPriority={symptomPriority}
              response={response}
            />
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
