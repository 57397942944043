import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const titleStyleOverrides: ComponentsOverrides<Theme>['MuiDialogTitle'] = {
  root: {
    backgroundColor: COLOURS.DarkBlue,
    color: COLOURS.White,
  },
};

export const MuiDialogTitle = {
  styleOverrides: titleStyleOverrides,
};

const styleOverrides: ComponentsOverrides['MuiDialog'] = {
  paper: {
    '.patient-tutorial &': {
      borderRadius: `${spacing * 3}px`,
      minWidth: '60%',
      minHeight: '70%',
      '& .MuiTypography-root': {
        flexBasis: 0,
        flexGrow: 2,
        color: COLOURS.DarkBlue,
        display: 'flex',
        padding: `${spacing * 10}px`,
      },
      '& .MuiDialogTitle-root': {
        color: COLOURS.White,
        padding: `${spacing * 5}px`,
        '& div': {
          width: '100%',
        },
      },
      '.Mui-error + .MuiInputLabel-root': {
        color: COLOURS.Red,
      },
      '& .MuiTabs-root': {
        background: COLOURS.DarkBlue,
        width: '100%',
        maxWidth: '100%',
        '.MuiTab-root': {
          color: COLOURS.White,
        },
        '& .MuiTabs-flexContainer': {
          justifyContent: 'center',
        },
        '& .Mui-selected': {
          backgroundColor: 'inherit',
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      },
      '.MuiInputLabel-root': {
        backgroundColor: 'white',
      },
      '& .MuiButton-root': {
        color: COLOURS.White,
        backgroundColor: COLOURS.DarkBlue,
        borderRadius: 0,
        padding: `${spacing * 5}px`,
        fontSize: 'large',
      },
      '& .display-contents': {
        display: 'contents',
      },
    },
    '.update-care-priorities &': {
      borderRadius: `${spacing * 3}px`,
      minWidth: '90%',
      minHeight: '90%',

      '& .select-priorities-title': {
        '& svg': {
          maxWidth: `${spacing * 30}px`,
        },
      },
      '& .MuiDialogTitle-root': {
        backgroundColor: COLOURS.White,
        color: 'unset',
        padding: `${spacing * 5}px`,
        paddingBottom: 0,
        '& div': {
          width: '100%',
        },
        '& button': {
          backgroundColor: COLOURS.Primary,
          color: COLOURS.White,
          padding: spacing,
        },
      },
      '& button': {
        minWidth: 'unset',
        maxWidth: '65%',
      },
      '& .MuiDialogActions-root': {
        backgroundColor: 'unset',
        justifyContent: 'center',
        '& button': {
          color: COLOURS.DarkBlue,
          borderColor: COLOURS.DarkBlue,
          padding: `${spacing * 2}px ${spacing * 4}px`,
        },
        '& .blue-button': {
          color: COLOURS.White,
          backgroundColor: COLOURS.DarkBlue,
          padding: `${spacing * 2}px ${spacing * 8}px`,
        },
      },
    },
  },
};

export const MuiDialog = {
  styleOverrides: styleOverrides,
};
