import {
  Fragment,
  PropsWithRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  Patient,
  PatientPriorityResponse,
  SymptomPriority,
} from 'ecarepd-shared-utilities';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { CarePriorityTip } from './CarePriorityTip';
import { useTranslation } from 'react-i18next';
import { CarePriorityScale } from './CarePriorityScale';
import { useInstancesByQuery } from 'hive-react-utils';
import { SymptomDetailsDialog } from './SymptomDetailsDialog';

const TIP_COUNT = 2;

export interface CarePriorityProps {
  entryCount: number;
  patient?: Patient;
  symptomPriority: SymptomPriority;
}
export function CarePriorityItem({
  entryCount,
  patient,
  symptomPriority,
}: PropsWithRef<CarePriorityProps>): JSX.Element {
  const { t } = useTranslation();

  // Randomly select tips only once to prevent changing on reactive events
  // Use indexes in order to adapter to language changes (different symptomPriority objects)
  const initialized = useRef(false);
  const [tipIndexes, setTipIndexes] = useState<number[]>([]);
  useEffect(() => {
    if (initialized.current || !symptomPriority) {
      return;
    }

    setTipIndexes(
      _.sampleSize(
        _.times(_.size(symptomPriority.properties.careTips)),
        TIP_COUNT
      )
    );

    initialized.current = true;
  }, [symptomPriority]);

  // Extract the tips from the priorities with the tipIndexes (if determined)
  const tips = useMemo(() => {
    if (!symptomPriority || _.isEmpty(tipIndexes)) {
      return;
    }

    return _.filter(symptomPriority.properties.careTips, (_v, i) =>
      _.includes(tipIndexes, i)
    );
  }, [symptomPriority, tipIndexes]);

  const responses = useInstancesByQuery<PatientPriorityResponse>(
    'ecarepd',
    'getLatestPatientPriorityResponsesByPriorityID',
    'patientPriorityResponse',
    { debounce: true, debounceWait: 1000 },
    patient?.properties?.patientID || '',
    symptomPriority.id,
    entryCount
  );

  const processedResponses = useMemo<PatientPriorityResponse[]>(() => {
    return (
      _.sortBy(responses, (r: PatientPriorityResponse) => r.properties.date2) ||
      []
    );
  }, [responses]);

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="patient-priority-history-item">
      <SymptomDetailsDialog
        open={showDetails}
        symptomPriority={symptomPriority}
        onClose={() => setShowDetails(false)}
      />

      <Accordion elevation={0} id={symptomPriority.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{symptomPriority.properties.name}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={1}>
            <CarePriorityScale
              symptomPriority={symptomPriority}
              responses={processedResponses}
            />

            <Typography variant="h4">
              {t('content.history.care_priority_history.recent_tips')}
            </Typography>

            {_.map(tips, (tip: string) => (
              <Fragment key={tip}>
                <CarePriorityTip tip={tip} />
              </Fragment>
            ))}

            <Link onClick={() => setShowDetails(true)}>
              {t('content.history.care_priority_history.full_tip_sheet_link')}
            </Link>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
