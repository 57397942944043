import { PatientService, PatientStatus } from 'ecarepd-shared-utilities';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  PATH_CARE_FINDER,
  PATH_CARE_TEAM,
  PATH_HOME,
  PATH_HISTORY,
  PATH_JOURNAL,
  PATH_RESOURCES,
  PATH_TRACKING,
  PATH_SETTINGS,
  PATH_EDITINFO,
} from '../content/constants';
import { DataContext } from '../contexts/DataContext';
import CareFinder from './CareFinder';
import CareTeam from './CareTeam';
import History from './history/History';
import Home from './Home';
import Journal from './Journal';
import Resources from './Resources';
import { EditInfo } from './settings/EditInfo';
import { Settings } from './settings/Settings';
import Tracking from './tracking/Tracking';

export default function Content(): JSX.Element {
  // If we get here, it means the user has logged in. If the user is "Pending", we can now activate
  const { patient } = useContext(DataContext);
  useEffect(() => {
    if (patient?.properties?.status === PatientStatus.Pending) {
      PatientService.updatePatient(patient, { status: 'Activated' });
    }
  }, [patient]);

  return (
    <Routes>
      <Route path={PATH_CARE_FINDER} element={<CareFinder />} />
      <Route path={PATH_CARE_TEAM} element={<CareTeam />} />
      <Route path={PATH_HISTORY} element={<History />} />
      <Route path={PATH_JOURNAL} element={<Journal />} />
      <Route path={PATH_RESOURCES} element={<Resources />} />
      <Route path={PATH_TRACKING} element={<Tracking />} />
      <Route path={PATH_HOME} element={<Home />} />
      <Route path={PATH_SETTINGS} element={<Settings />} />
      <Route path={PATH_EDITINFO} element={<EditInfo />} />
      <Route path="*" element={<Navigate to={PATH_HOME} />} />
    </Routes>
  );
}
