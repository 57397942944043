import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState, } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useHiveConfig } from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
function Progress() {
    return _jsx(CircularProgress, {});
}
function Error({ error }) {
    const message = useMemo(() => error.message || error.name || error.toString(), [error]);
    return _jsx(Typography, Object.assign({ color: "error" }, { children: message }));
}
export function Authenticating() {
    const [progress, setProgress] = useState(true);
    const [code, setCode] = useState(null);
    const [path, setPath] = useState('/');
    const [error, setError] = useState();
    const hiveConfig = useHiveConfig();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setCode(params.get('code'));
        setPath(params.get('path') || '/');
    }, []);
    useEffect(() => {
        if (!code || !hiveConfig) {
            return;
        }
        ConnectionService.signInFromOidcCode(hiveConfig, code)
            .then(() => {
            setProgress(false);
        })
            .catch(setError);
    }, [hiveConfig, code]);
    const content = useMemo(() => {
        if (error) {
            return _jsx(Error, { error: error });
        }
        if (progress) {
            return _jsx(Progress, {});
        }
        return _jsx(Navigate, { to: path });
    }, [error, progress, path]);
    return (_jsx(Stack, Object.assign({ flexGrow: "1", justifyContent: "center", alignItems: "center" }, { children: content })));
}
