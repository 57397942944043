import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useCallback, PropsWithRef } from 'react';
import TabPanel from './TabPanel';
import { ReactComponent as Diagnosis } from '../../assets/FTE4.svg';

export interface Tab4Props {
  tabValue: number;
  hasErrors: boolean;
  gender: 'M' | 'F' | '';
  setGender: (v: 'M' | 'F' | '') => void;
  diagnosticYear: string;
  setDiagnosticYear: (v: string) => void;
  yearOfBirth: string;
  setYearOfBirth: (v: string) => void;
  minimumYear: number;
}

export default function Tab4({
  tabValue,
  hasErrors,
  gender,
  setGender,
  diagnosticYear,
  setDiagnosticYear,
  yearOfBirth,
  setYearOfBirth,
  minimumYear,
}: PropsWithRef<Tab4Props>): JSX.Element {
  const { t } = useTranslation();

  const onChangeGender = useCallback(
    (event: SelectChangeEvent<'M' | 'F' | ''>) => {
      if (!event?.target?.value) {
        return;
      }
      const newGender = event.target.value === 'F' ? 'F' : 'M';
      setGender(newGender);
    },
    [setGender]
  );

  return (
    <TabPanel value={tabValue} index={3}>
      <Stack display="flex" flexDirection="row">
        <Stack
          flexDirection="column"
          flexBasis={0}
          flexGrow={2}
          justifyContent="space-evenly"
          paddingTop={10}
        >
          <Typography className="display-contents">
            {t('content.tutorial.tab4')}
          </Typography>
          <NumberFormat
            onValueChange={({ value }) => setDiagnosticYear(value)}
            customInput={TextField}
            type="tel"
            mask=" "
            name="diagnosed_date"
            format="####"
            isNumericString
            required
            error={
              hasErrors &&
              (!diagnosticYear || Number(diagnosticYear) < minimumYear)
            }
            label={t('content.tutorial.diagnosed_date')}
            placeholder={t('content.tutorial.diagnosed_date_placeholder')}
            value={diagnosticYear}
          />
          <NumberFormat
            onValueChange={({ value }) => setYearOfBirth(value)}
            customInput={TextField}
            mask=" "
            name="birth_date"
            format="####"
            required
            error={
              hasErrors && (!yearOfBirth || Number(yearOfBirth) < minimumYear)
            }
            isNumericString
            label={t('content.tutorial.birth_date')}
            placeholder={t('content.tutorial.birth_date_placeholder')}
            value={yearOfBirth}
          />
          <FormControl>
            <Select
              id="gender"
              value={gender}
              onChange={onChangeGender}
              required
              error={hasErrors && !gender}
            >
              <MenuItem value="F">{t('content.tutorial.female')}</MenuItem>
              <MenuItem value="M">{t('content.tutorial.male')}</MenuItem>
            </Select>
            <InputLabel id="demo-simple-select-helper-label">
              {t('content.tutorial.gender')}
            </InputLabel>
          </FormControl>
        </Stack>
        <Diagnosis />
      </Stack>
    </TabPanel>
  );
}
