import {
  Box,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  CommonDataContext,
  useMyJournalsByDate,
  useMyPatientPriorityResponses,
  useStartOfDay,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { DataContext } from '../contexts/DataContext';
import { useTranslation } from 'react-i18next';

export default function Journal(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const { patient } = useContext(DataContext);
  const { symptomPrioritiesById } = useContext(CommonDataContext);
  const startOfDay = useStartOfDay();
  const [date, setDate] = useState(startOfDay);
  const journals = useMyJournalsByDate(date, date);
  const journal = useMemo(() => _.first(journals), [journals]);
  const carePriorityResponses = useMyPatientPriorityResponses(
    patient?.properties.patientID || '',
    moment(date).startOf('day').toISOString(),
    moment(date).endOf('day').toISOString()
  );

  useEffect(() => {
    setDate(startOfDay);
  }, [startOfDay]);

  return (
    <Stack
      flex={1}
      direction={md ? 'row' : 'column'}
      spacing={8}
      overflow="hidden"
    >
      <Box overflow="auto">
        <Box
          width={320}
          height={330}
          margin={2}
          boxShadow="0px 3px 6px rgba(0, 0, 0, 0.246176)"
          borderRadius={2}
          overflow="hidden"
        >
          <StaticDatePicker
            value={date}
            onChange={(val) => {
              if (val) {
                setDate(val);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            minDate={moment(patient?.created)}
            displayStaticWrapperAs="desktop"
            disableFuture
            views={['day']}
          />
        </Box>
      </Box>
      <Stack flex={1} overflow="hidden" spacing={4}>
        <Box overflow="auto">
          <Stack spacing={4}>
            <Stack className="patient-journal-title" spacing={1}>
              <Typography variant="h5">{date.format('LL')}</Typography>
              {!!journal && (
                <Card className="patient-journal-entry-content">
                  <Typography variant="body1">
                    {journal.properties.entry}
                  </Typography>
                </Card>
              )}
            </Stack>
            {!!_.compact(_.map(carePriorityResponses, 'properties.note'))
              .length && (
              <Stack className="patient-journal-carePriority" spacing={1}>
                <Typography variant="h6">
                  {t('content.journal.carePriority')}
                </Typography>
                <Card>
                  <Stack spacing={2}>
                    {_.map(
                      carePriorityResponses,
                      (carePriorityResponse) =>
                        !!carePriorityResponse.properties.note && (
                          <Stack
                            direction="row"
                            key={carePriorityResponse.id}
                            className="patient-journal-carePriority-content"
                          >
                            <Typography variant="body1" noWrap>
                              {`${_.get(
                                _.get(
                                  symptomPrioritiesById,
                                  carePriorityResponse.properties.priorityID
                                ),
                                'properties.name'
                              )} - `}
                            </Typography>
                            <Box flex={1}>
                              <Typography variant="body2">
                                {carePriorityResponse.properties.note}
                              </Typography>
                            </Box>
                          </Stack>
                        )
                    )}
                  </Stack>
                </Card>
              </Stack>
            )}
          </Stack>
        </Box>
        {!date.isSame(startOfDay, 'day') && (
          <Stack flex={1}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setDate(startOfDay)}
            >
              {t('common.back')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
