import { useInstancesByQuery } from 'hive-react-utils';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
// convertion required to have (value, index, collection) for the functional map function instead of only (value)
// eslint-disable-next-line @typescript-eslint/naming-convention
const _fp = fp.convert({ cap: false });
const DATE_FORMAT = 'MMM DD';
function generateEmptyStats(startDate, endDate) {
    const numberOfDays = moment(endDate).diff(moment(startDate), 'days');
    return _fp.flow([
        _fp.times((i) => {
            const m = moment(startDate)
                .startOf('day')
                .add(i + 1, 'day');
            return {
                [m.toISOString()]: [{ properties: { date2: m.toISOString() } }],
            };
        }),
        _fp.assignInAll,
    ])(numberOfDays);
}
function extractPriorityIds(priorityResponses) {
    return _fp.flow([
        // This comment is to prevent the prettier to complain about this multi-line flow (for readability!)
        _fp.groupBy('properties.priorityID'),
        _fp.keys,
    ])(priorityResponses);
}
export function useStatsFromPriorityResponses(priorityResponses, emptyStats) {
    const [stats, setStats] = useState([]);
    const [priorityIds, setPriorityIds] = useState([]);
    useEffect(() => {
        if (!priorityResponses ||
            _.get(priorityResponses, 'status') !== "done" /* ContentObserver.status.DONE */) {
            return;
        }
        // get the priority ids
        const extractedPriorityIds = extractPriorityIds(priorityResponses);
        setPriorityIds(extractedPriorityIds);
        // Now that we have extracted the priority names, we can continue processing the data
        const processed = _fp.flow([
            // Make sure the date2 properties are all at the start of the day
            _fp.map((p) => {
                const date = moment(p.properties.date2).startOf('day').toISOString();
                return _.set(p, 'properties.date2', date);
            }),
            // Group by date and format as "MonthName Day"
            _fp.groupBy('properties.date2'),
            // Merge over the empty stats so that we have an entry for every day, even if there is no data
            (s) => _.merge({}, emptyStats, s),
            _fp.mapKeys((_ignored, d) => moment(d).local().format(DATE_FORMAT)),
            // Reformat values as a single entry with each datapoint as a single item
            _fp.mapValues(_fp.flow([
                _fp.map((p) => {
                    var _a, _b;
                    return ({
                        // The timestamp will be important for sorting!
                        timestamp: moment((_a = p === null || p === void 0 ? void 0 : p.properties) === null || _a === void 0 ? void 0 : _a.date2).unix(),
                        [((_b = p === null || p === void 0 ? void 0 : p.properties) === null || _b === void 0 ? void 0 : _b.priorityID) || '']: {
                            id: p.id,
                            value: p.properties.response,
                            note: p.properties.note,
                        },
                    });
                }),
                _fp.assignAll,
            ])),
            // Now reformat so that the date is part of each entry
            _fp.map((values, date) => {
                return Object.assign({ date }, values);
            }),
            // Since the previous map is on an object, we are never sure of the order. Remember that timestamp?
            // We now use it for sorting
            _fp.sortBy('timestamp'),
        ])(priorityResponses);
        // TODO: change today's date to 'Today', if possible. Chart might order differently
        setStats(processed);
    }, [priorityResponses, emptyStats]);
    return [stats, priorityIds];
}
export function useStatsByPatientId(patientId, startDate, endDate) {
    const emptyStats = useMemo(() => generateEmptyStats(startDate, endDate), [startDate, endDate]);
    const priorityResponses = useInstancesByQuery('ecarepd', 'getPatientPriorityResponses', 'patientPriorityResponse', {
        debounce: true,
        debounceWait: 2000,
        debounceMaxWait: 15000,
    }, patientId, moment(startDate).toISOString(), moment(endDate).toISOString());
    return useStatsFromPriorityResponses(priorityResponses, emptyStats);
}
