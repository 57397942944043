import { useContext } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { Box, Stack } from '@mui/material';
import { DeleteAccount } from './DeleteAccount';
import { ResetPassword } from './ResetPassword';
import { YourInfo } from './YourInfo';

export function Settings(): JSX.Element {
  const { patient } = useContext(DataContext);

  if (patient) {
    return (
      <Stack
        className="settings"
        flexGrow={1}
        spacing={2}
        padding={2}
        maxWidth="500px"
        justifyContent="space-between"
      >
        <YourInfo />

        <Box padding={4}>
          <ResetPassword />
        </Box>

        <Box padding={4}>
          <DeleteAccount />
        </Box>
      </Stack>
    );
  }
  return <></>;
}
