import { Typography, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from './TabPanel';
import { ReactComponent as Setting } from '../../assets/FTE5.svg';

export default function Tab5(props: {
  onChange: (e: any, v: number | string) => void;
  value: number;
}) {
  const { value, onChange } = props;
  const { t } = useTranslation();

  return (
    <TabPanel value={value} index={4}>
      <Stack display="flex" flexDirection="row">
        <Setting />
        <Stack
          flexDirection="column"
          flexBasis={0}
          flexGrow={2}
          justifyContent="space-evenly"
          paddingTop={10}
        >
          <Typography>{t('content.tutorial.tab5')}</Typography>
          <Button onClick={() => onChange({}, value + 1)} variant="outlined">
            {t('content.tutorial.skip')}
          </Button>
        </Stack>
      </Stack>
    </TabPanel>
  );
}
