import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { createElement, Fragment, useContext, useMemo, } from 'react';
import { CommonDataContext } from '../../contexts';
import { scaleValueByResponse } from '../ScaleConstants';
import { ReactComponent as Tick } from './legends/tick.svg';
function getIdFromKey(key) {
    const match = key.match(/^([^.]*).value$/);
    if (!match) {
        return key;
    }
    const [, id] = match;
    return id;
}
const labelFormatter = (label) => moment.unix(label).format('LL');
function ChartLegendRow({ color, dataKey, value, }) {
    const { symptomPrioritiesById } = useContext(CommonDataContext);
    const name = useMemo(() => {
        const id = getIdFromKey(dataKey);
        return _.get(symptomPrioritiesById, [id, 'properties', 'name']);
    }, [symptomPrioritiesById, value]);
    const icon = useMemo(() => {
        const scaleValue = scaleValueByResponse(value);
        if (scaleValue === null || scaleValue === void 0 ? void 0 : scaleValue.icon) {
            return createElement(scaleValue.icon, { width: 32, height: 32 });
        }
        return value;
    }, [symptomPrioritiesById, value]);
    if (value < 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, Object.assign({ direction: "row", color: color, alignItems: "center", spacing: 1 }, { children: [_jsx(Tick, {}), _jsx(Typography, Object.assign({ variant: "body1", color: color }, { children: name }))] })), _jsx(Stack, Object.assign({ marginLeft: 1 }, { children: icon }))] }));
}
export function ChartLegend({ label, payload, }) {
    return (_jsxs(Paper, { children: [_jsx(Typography, Object.assign({ variant: "h4" }, { children: labelFormatter(label) })), _jsx(Stack, Object.assign({ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center" }, { children: _.map(payload, (p) => (_jsx(Fragment, { children: _jsx(ChartLegendRow, Object.assign({}, p)) }, p.dataKey))) }))] }));
}
