var _a;
import { ConnectionService, utils } from 'hive-client-utils';
import _ from 'lodash';
import moment from 'moment';
const REDIRECT_URI = (_a = window._env_) === null || _a === void 0 ? void 0 : _a.REACT_APP_PATIENT_APP_URL;
function invitePatient(firstName, lastName, email, clinicianId) {
    const connection = ConnectionService.assertConnection();
    const handler = utils.waitForInvocationReaction(connection.bee, _.identity);
    return connection.bee.actions
        .invoke('invitePatient', firstName, lastName, email, REDIRECT_URI, clinicianId)
        .then(handler);
}
function setPatientPriorities(patient, priorities) {
    const connection = ConnectionService.assertConnection();
    return connection.bee.actions
        .invoke('setPatientPriorities', patient.properties.patientID, priorities)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function assertJournalEntry(patient, entry) {
    const connection = ConnectionService.assertConnection();
    const date = moment().startOf('day').toISOString();
    return connection.bee.actions
        .invoke('assertJournalEntry', patient.properties.patientID, date, entry)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function updatePatientPriorityResponse(responseId, props) {
    if (_.isEmpty(props)) {
        // Nothing to update!
        return Promise.resolve();
    }
    const connection = ConnectionService.assertConnection();
    return connection.bee.actions
        .invoke('updatePatientPriorityResponse', responseId, props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function deleteMyAccount() {
    const connection = ConnectionService.assertConnection();
    return connection.bee.actions
        .invoke('deleteMyAccount')
        .then(utils.waitForInvocationReaction(connection.bee))
        .then(() => {
        ConnectionService.releaseBee().catch(console.error);
    });
}
function addPatientPriorityResponse(priorityId, date, response, note) {
    const connection = ConnectionService.assertConnection();
    return connection.bee.actions
        .invoke('addPatientPriorityResponse', priorityId, moment(date).toISOString(), response, note || '')
        .then(utils.waitForInvocationReaction(connection.bee));
}
function updatePatient(patient, props) {
    if (_.isEmpty(props)) {
        return Promise.resolve();
    }
    const connection = ConnectionService.assertConnection();
    return connection.bee.actions
        .invoke('updatePatient', patient.id, props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment(date).format('YYYY');
}
function createCallRequest(patient, priorityID, date, comment) {
    const connection = ConnectionService.assertConnection();
    date = moment(date).toISOString();
    return connection.bee.actions
        .invoke('createCallRequest', patient.id, priorityID, date, comment)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function completeCallRequest(requestId) {
    const connection = ConnectionService.assertConnection();
    return connection.bee.actions
        .invoke('completeCallRequest', requestId)
        .then(utils.waitForInvocationReaction(connection.bee));
}
export const PatientService = {
    invitePatient,
    setPatientPriorities,
    assertJournalEntry,
    updatePatientPriorityResponse,
    deleteMyAccount,
    addPatientPriorityResponse,
    updatePatient,
    formatDate,
    createCallRequest,
    completeCallRequest,
};
