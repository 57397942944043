import { Box } from '@mui/material';
import { PropsWithRef } from 'react';

export interface TabPanelProps {
  children: any;
  value: number;
  index: number;
}

export default function TabPanel({
  children,
  value,
  index,
}: PropsWithRef<TabPanelProps>): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
