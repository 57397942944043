import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiCard'] = {
  root: {
    boxShadow: 'inset 0px 1px 3px 0px rgba(0,0,0,0.5)',
    padding: `${spacing * 2.5}px`,
    backgroundColor: COLOURS.LightBlueBackground + '40', // adding 25% opacity to color
    '.update-care-priorities &': {
      borderRadius: spacing * 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 2}px`,
      margin: spacing * 3,
      background: COLOURS.CardBackgroundColor,
      border: `1px solid ${COLOURS.CardBorderColor}`,
      boxShadow: 'none',
      '&.selected-card': {
        backgroundColor: COLOURS.DarkBlue,
        borderColor: COLOURS.White,
        '> .MuiTypography-root': {
          color: COLOURS.White,
        },
        '& .MuiSvgIcon-root': {
          transform: 'rotate(45deg)',
          color: COLOURS.White,
        },
      },
      '& .MuiSvgIcon-root': {
        color: COLOURS.DarkBlue,
      },
    },
    '.care-priority-card &': {
      flexDirection: 'column',
      backgroundColor: 'unset',
      color: COLOURS.Primary,
      width: '21%', // TODO: figure out why 20% changes the appearance drastically
      borderRadius: spacing * 2,
      justifyContent: 'space-between',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
      '.MuiTypography-root': {
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'noWrap',
      },
      '.MuiSvgIcon-root': {
        transform: 'rotate(45deg)',
        color: COLOURS.Primary,
      },
      '.MuiButton-root': {
        padding: 0,
        alignSelf: 'flex-start',
        textTransform: 'none',
        textDecoration: 'underline',
        color: COLOURS.Primary,
      },
    },
    '&.care-priorities-home': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '.MuiButton-root': {
        padding: 0,
        alignSelf: 'flex-start',
        textTransform: 'none',
        textDecoration: 'underline',
        color: COLOURS.Primary,
      },
    },
  },
};

export const MuiCard = {
  styleOverrides: styleOverrides,
};
