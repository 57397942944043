import { ThemeProvider } from '@mui/material';
import { useAppVersion, useTitleEffect } from 'hive-react-utils';
import { useEffect } from 'react';
import { patientTheme } from './theme';
import Main from './content/Main';

import {
  CommonDataContextProvider,
  MessageBarProvider,
} from 'ecarepd-shared-utilities';
import { DataContextProvider } from './contexts/DataContext';
import { ConfirmProvider } from 'material-ui-confirm';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function App() {
  useTitleEffect('app.title');

  const { version } = useAppVersion();
  useEffect(() => console.debug(`App Version: ${version}`), [version]);

  return (
    <ThemeProvider theme={patientTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MessageBarProvider>
          <CommonDataContextProvider>
            <DataContextProvider>
              <ConfirmProvider>
                <Main />
              </ConfirmProvider>
            </DataContextProvider>
          </CommonDataContextProvider>
        </MessageBarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
