import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { useStatsByPatientId, useStatsFromPriorityResponses, useSymptomPriorities, } from '../../hooks';
import { ChartFooter } from './ChartFooter';
import { ChartHeader } from './ChartHeader';
import { ChartBody } from './ChartBody';
import moment from 'moment';
import _ from 'lodash';
const STORAGE_CHART_PERIOD = 'chart-period';
function ChartWithControls({ patient, titleId, displayPeriodId, }) {
    const [period, setPeriod] = useState(() => parseInt(localStorage.getItem(STORAGE_CHART_PERIOD) || '30'));
    const changePeriod = useCallback((event) => {
        const newPeriod = event.target.value;
        setPeriod(newPeriod);
        localStorage.setItem(STORAGE_CHART_PERIOD, newPeriod.toString());
    }, []);
    const [startDate, setStartDate] = useState(() => moment().subtract(period, 'day').startOf('day').toISOString());
    useEffect(() => {
        const newStartDate = moment()
            .subtract(period, 'day')
            .startOf('day')
            .toISOString();
        setStartDate(newStartDate);
    }, [period]);
    const [stats, priorityIds] = useStatsByPatientId(patient.properties.patientID, startDate, moment().endOf('day').toISOString());
    const initialized = useRef(false);
    const allPriorities = useSymptomPriorities();
    const [visiblePriorites, setVisiblePriorities] = useState(new Set());
    useEffect(() => {
        if (initialized.current || _.isEmpty(allPriorities)) {
            return;
        }
        const allPriorityIds = new Set(_.map(allPriorities, (p) => p.id));
        setVisiblePriorities(allPriorityIds);
        initialized.current = true;
    }, [initialized, allPriorities]);
    const togglePriorityVisibility = useCallback((id) => {
        if (!initialized) {
            return;
        }
        let updated = new Set(visiblePriorites);
        if (updated.has(id)) {
            updated.delete(id);
        }
        else {
            updated.add(id);
        }
        setVisiblePriorities(updated);
    }, [initialized, visiblePriorites]);
    return (_jsxs(Stack, Object.assign({ direction: "column", height: "100%", spacing: 2, padding: 2, className: "patient-chart" }, { children: [_jsx(ChartHeader, { titleId: titleId, displayPeriodId: displayPeriodId, period: period, changePeriod: changePeriod }), _jsx(Stack, Object.assign({ flexGrow: 1 }, { children: _jsx(ChartBody, { patient: patient, stats: stats, priorityIds: priorityIds, visiblePriorites: visiblePriorites }) })), _jsx(ChartFooter, { priorityIds: priorityIds, visiblePriorities: visiblePriorites, toggleVisiblePriority: togglePriorityVisibility })] })));
}
function ChartWithResponses({ patient, responses, }) {
    const [stats, priorityIds] = useStatsFromPriorityResponses(responses);
    const initialized = useRef(false);
    const allPriorities = useSymptomPriorities();
    const [visiblePriorites, setVisiblePriorities] = useState(new Set());
    useEffect(() => {
        if (initialized.current || _.isEmpty(allPriorities)) {
            return;
        }
        const allPriorityIds = new Set(_.map(allPriorities, (p) => p.id));
        setVisiblePriorities(allPriorityIds);
        initialized.current = true;
    }, [initialized, allPriorities]);
    const togglePriorityVisibility = useCallback((id) => {
        if (!initialized) {
            return;
        }
        let updated = new Set(visiblePriorites);
        if (updated.has(id)) {
            updated.delete(id);
        }
        else {
            updated.add(id);
        }
        setVisiblePriorities(updated);
    }, [initialized, visiblePriorites]);
    return (_jsxs(Stack, Object.assign({ flexGrow: "1", spacing: 2, padding: 2, className: "patient-chart" }, { children: [_jsx(Stack, Object.assign({ flexGrow: "1" }, { children: _jsx(ChartBody, { patient: patient, stats: stats, priorityIds: priorityIds, visiblePriorites: visiblePriorites }) })), _jsx(ChartFooter, { priorityIds: priorityIds, visiblePriorities: visiblePriorites, toggleVisiblePriority: togglePriorityVisibility })] })));
}
export function PatientChart({ patient, titleId, displayPeriodId, responses, }) {
    if (responses) {
        return _jsx(ChartWithResponses, { patient: patient, responses: responses });
    }
    return (_jsx(ChartWithControls, { patient: patient, titleId: titleId, displayPeriodId: displayPeriodId }));
}
