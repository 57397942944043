import {
  PatientService,
  useApiCallWithMessageBar,
  StepDialog,
  StepDialogStep,
} from 'ecarepd-shared-utilities';
import moment from 'moment';
import { PropsWithRef, useContext, useEffect, useMemo, useState } from 'react';
import { DefaultNamespace, TFunction, useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';
import { CarePrioritySelection } from './CarePrioritySelection';
import { Comment as CommentTab } from './Comment';
import { Done as DoneTab } from './Done';

interface CallRequestStepParameters {
  priority?: string;
  setPriority: (newPriority: string) => void;

  comment?: string;
  setComment: (newComment: string) => void;
}

const STEPS: StepDialogStep<CallRequestStepParameters>[] = [
  {
    isFirst: true,
    isLast: false,
    nextLabel: (t: TFunction<DefaultNamespace>) => t('common.next'),
    isNextDisabled: ({ priority }: CallRequestStepParameters) => !priority,
    component: ({ priority, setPriority }: CallRequestStepParameters) => (
      <CarePrioritySelection priority={priority} setPriority={setPriority} />
    ),
  },

  {
    isFirst: false,
    isLast: false,
    nextLabel: (t: TFunction<DefaultNamespace>) => t('common.next'),
    isNextDisabled: () => false,
    component: ({ comment, setComment }: CallRequestStepParameters) => (
      <CommentTab comment={comment} setComment={setComment} />
    ),
  },

  {
    isFirst: false,
    isLast: true,
    nextLabel: (t: TFunction<DefaultNamespace>) => t('common.continue'),
    isNextDisabled: () => false,
    component: ({ priority }: CallRequestStepParameters) => (
      <DoneTab priority={priority || ''} />
    ),
  },
];

export interface CallRequestDialogProps {
  open: boolean;
  onClose: () => void;
}

export function CallRequestDialog({
  open,
  onClose,
}: PropsWithRef<CallRequestDialogProps>): JSX.Element {
  const { t } = useTranslation();

  const { patient } = useContext(DataContext);

  const [priority, setPriority] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const stepParameters = useMemo(
    () => ({ priority, setPriority, comment, setComment }),
    [comment, priority]
  );

  useEffect(() => {
    // Reset values when opening
    if (open) {
      setPriority('');
      setComment('');
    }
  }, [open]);

  const { progress: submitting, callback: onContinue } =
    useApiCallWithMessageBar({
      canExecute: () => !!patient,
      execute: () =>
        PatientService.createCallRequest(
          patient!,
          priority || '',
          moment().toISOString(),
          comment
        ).then(onClose),
    });

  return (
    <StepDialog
      open={open}
      onClose={onClose}
      disableEscape={true}
      disableBackdropClick={true}
      dialogTitle={t('call_request.title')}
      steps={STEPS}
      stepParameters={stepParameters}
      submitting={submitting}
      onContinue={onContinue}
    />
  );
}
