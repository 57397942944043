import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Stack,
  TextField,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  PatientService,
  UpdatePatientProps,
} from 'ecarepd-shared-utilities/src/services/PatientService';
import { useApiCallWithMessageBar } from 'ecarepd-shared-utilities';

import { PATH_SETTINGS } from '../constants';
import { useNavigate } from 'react-router-dom';

export function EditInfo(): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [diagnosticDate, setDiagnosticDate] = useState<string | undefined>();
  const [dob, setDOB] = useState<string | undefined>();
  const [gender, setGender] = useState<'M' | 'F' | ''>('');
  const [allowContact, setAllowContact] = useState<boolean>(true);

  const { progress: submitting, callback: onUpdatePriorities } =
    useApiCallWithMessageBar({
      canExecute: () => !!patient,
      execute: async () => {
        if (patient) {
          const o: UpdatePatientProps = {
            firstname: firstName,
            lastname: lastName,
            phone,
            diagnosticDate,
            dateOfBirth: dob,
            gender: gender === '' ? undefined : gender,
            allowContact,
          };

          await PatientService.updatePatient(patient, o);
          navigate(PATH_SETTINGS);
        }
      },
      success: {
        snackbarProps: {
          autoHideDuration: 1000,
        },
      },
    });

  useEffect(() => {
    setFirstName(patient?.properties.firstname || '');
    setLastName(patient?.properties.lastname || '');
    setPhone(patient?.properties.phone || '');
    setDiagnosticDate(patient?.properties.diagnosticDate);
    setDOB(patient?.properties.dateOfBirth);
    setGender(patient?.properties.gender || '');
    setAllowContact(
      patient?.properties.allowContact != null
        ? patient?.properties.allowContact
        : true
    );
  }, [patient]);

  const onFirstNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName(e.target.value);
    },
    [setFirstName]
  );

  const onLastNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLastName(e.target.value);
    },
    [setLastName]
  );

  const onPhoneChange = useCallback(
    (values: NumberFormatValues) => {
      setPhone(values.value);
    },
    [setPhone]
  );

  const onDiagnosticDateChange = useCallback(
    (newValue: moment.Moment | null) => {
      setDiagnosticDate(newValue?.toISOString());
    },
    [setDiagnosticDate]
  );

  const onDOBChange = useCallback(
    (newValue: moment.Moment | null) => {
      setDOB(newValue?.toISOString());
    },
    [setDOB]
  );

  const onAllowContactChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAllowContact(e.target.checked);
    },
    [setAllowContact]
  );

  if (patient) {
    return (
      <Stack
        className="patient-editInfo-content"
        spacing={5}
        maxWidth={500}
        alignItems={'left'}
      >
        <Typography variant="h5">{t('settings.your_info.title')}</Typography>

        <Stack direction="row" spacing={4}>
          <TextField
            id="firstname"
            label={t('settings.your_info.edit_info.first_name')}
            value={firstName}
            onChange={onFirstNameChange}
            variant="outlined"
            fullWidth
          />

          <TextField
            id="lastname"
            label={t('settings.your_info.edit_info.last_name')}
            value={lastName}
            onChange={onLastNameChange}
            variant="outlined"
            fullWidth
          />
        </Stack>

        <NumberFormat
          onValueChange={onPhoneChange}
          customInput={TextField}
          type="tel"
          mask=" "
          name="telephone"
          format="(###) ###-####"
          isNumericString
          error={false}
          label={t('settings.your_info.edit_info.phone_number')}
          placeholder={t('settings.your_info.edit_info.phone_number')}
          value={phone}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={allowContact} onChange={onAllowContactChange} />
            }
            label={t('settings.your_info.edit_info.allow_sms')}
          />
        </FormGroup>

        <Typography variant="body1">
          {t('settings.your_info.edit_info.email')}
        </Typography>
        <Typography variant="body2">{patient?.properties.email}</Typography>
        <Typography variant="h6">
          {t('settings.your_info.edit_info.email_subtext')}
        </Typography>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={t('settings.your_info.diagnosis_date')}
            value={diagnosticDate}
            onChange={onDiagnosticDateChange}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={t('settings.your_info.dob')}
            value={dob}
            onChange={onDOBChange}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel>{t('settings.your_info.gender')}</InputLabel>
          <Select
            value={gender}
            label={t('settings.your_info.gender')}
            onChange={(e: SelectChangeEvent<'M' | 'F' | ''>) => {
              setGender(e.target.value === 'M' ? 'M' : 'F');
            }}
          >
            <MenuItem value="F">{t('content.tutorial.female')}</MenuItem>
            <MenuItem value="M">{t('content.tutorial.male')}</MenuItem>
          </Select>
        </FormControl>

        <Box>
          <Button
            variant="outlined"
            onClick={onUpdatePriorities}
            disabled={submitting}
          >
            {t('settings.your_info.edit_info.save')}
          </Button>
        </Box>
      </Stack>
    );
  }

  return <></>;
}
