import {
  Button,
  Container,
  IconButton,
  Link,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  PatientPriorityResponseProperties,
  ScaleIcon,
  SymptomPriority,
  TextDialog,
  TextDialogProps,
  ScaleValueResponses,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import {
  MouseEvent,
  PropsWithRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NoteDialog } from '../../components/dialog';
import { SymptomDetailsDialog } from '../history/SymptomDetailsDialog';
import { ReactComponent as Tip } from '../../assets/tip.svg';

const SCALE_DISPLAY_ORDER = [
  ScaleValueResponses.VeryHappy,
  ScaleValueResponses.Happy,
  ScaleValueResponses.Meh,
  ScaleValueResponses.Sad,
  ScaleValueResponses.VerySad,
];

interface ScaleIconWithTipProps {
  value: ScaleValueResponses;
  selectedValue?: ScaleValueResponses;
  tipShown?: ScaleValueResponses;
  showTipDialog: () => void;
}

function ScaleIconWithTip({
  value,
  selectedValue,
  tipShown,
  showTipDialog,
}: PropsWithRef<ScaleIconWithTipProps>): JSX.Element {
  const handleTipClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      showTipDialog();
    },
    [showTipDialog]
  );

  return (
    <Container>
      <ScaleIcon highlighted={selectedValue === value} response={value} />

      {tipShown === value && (
        <IconButton onClick={handleTipClick}>
          <Tip />
        </IconButton>
      )}
    </Container>
  );
}

export interface PriorityProps {
  priority: SymptomPriority;
  response: PatientPriorityResponseProperties;
  changeResponse: (response?: number, note?: string) => void;
}

export function Priority({
  priority,
  response,
  changeResponse,
}: PropsWithRef<PriorityProps>): JSX.Element {
  const { t } = useTranslation();

  const [tipShown, setTipShown] = useState<number | undefined>(undefined);
  const tipShownRef = useRef(tipShown);

  const [tipDialogProps, setTipDialogProps] = useState<
    TextDialogProps | undefined
  >();

  const showTipDialog = useCallback(() => {
    setTipDialogProps({
      open: true,
      onClose: () => setTipDialogProps(undefined),
      title: t('content.tracking.priorities.care_tip_title', {
        symptom: priority.properties.name,
      }),
      text: _.sample(priority.properties.careTips) || '',
    });
  }, [priority.properties.careTips, priority.properties.name, t]);

  const selectedValue = useMemo<ScaleValueResponses | undefined>(
    () => response?.response,
    [response]
  );

  const onValueChanged = useCallback(
    (_event, newValue) => {
      // Check if we can show the tip pop-up
      if (
        // We have a actual selection and not a deselection
        newValue != null &&
        // We have Sad or verySade
        Number(newValue) < ScaleValueResponses.Meh &&
        // No tips were previously displayed
        tipShownRef.current === undefined
      ) {
        setTipShown(Number(newValue));
        tipShownRef.current = Number(newValue);

        showTipDialog();
      }

      // nil means unselected
      changeResponse(newValue == null ? -1 : newValue);
    },
    [changeResponse, showTipDialog]
  );

  const note = useMemo(() => response?.note || '', [response]);
  const changeNote = useCallback(
    (newNote: string) => {
      changeResponse(undefined, newNote);
    },
    [changeResponse]
  );

  const [noteDialogOpen, setNoteDialogOpen] = useState(false);

  const [infoAnchor, setInfoAnchor] = useState<HTMLButtonElement | null>(null);

  const onMoreInfo = (event: any) => {
    setInfoAnchor(event.target);
  };

  const onCloseInfo = () => {
    setInfoAnchor(null);
  };

  const [showDetails, setShowDetails] = useState(false);

  const onMoreAbout = useCallback(() => {
    setTipDialogProps(undefined);
    setShowDetails(true);
  }, []);

  return (
    <>
      <NoteDialog
        symptomPriority={priority}
        open={noteDialogOpen}
        onClose={() => setNoteDialogOpen(false)}
        note={note}
        setNote={changeNote}
      />

      <TextDialog
        open={!!tipDialogProps}
        actions={[
          <Button key="more–about" onClick={onMoreAbout}>
            {t('content.tracking.priorities.more_about', {
              symptom: priority.properties.name,
            })}
          </Button>,
          <Button key="continue" onClick={() => setTipDialogProps(undefined)}>
            {t('common.continue')}
          </Button>,
        ]}
        {...tipDialogProps}
      />

      <SymptomDetailsDialog
        open={showDetails}
        symptomPriority={priority}
        onClose={() => setShowDetails(false)}
      />

      <Popover
        id={priority?.id}
        open={!!infoAnchor}
        anchorEl={infoAnchor}
        onClose={onCloseInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="body1">
          {priority?.properties?.information}
        </Typography>
      </Popover>

      <Stack
        direction="row"
        spacing={8}
        alignItems="center"
        padding={4}
        // See index.css about this
        className="patient-priority-background"
      >
        <Stack flexGrow="1">
          <Typography variant="h5">{priority.properties.name}</Typography>
          <Link variant="body2" onClick={onMoreInfo}>
            {t('content.tracking.priorities.more_info')}
          </Link>
        </Stack>

        <ToggleButtonGroup
          exclusive
          value={selectedValue}
          onChange={onValueChanged}
        >
          {_.map(SCALE_DISPLAY_ORDER, (value: ScaleValueResponses) => (
            <ToggleButton key={value} value={value}>
              <ScaleIconWithTip
                value={value}
                selectedValue={selectedValue}
                tipShown={tipShown}
                showTipDialog={showTipDialog}
              />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <Stack>
          <Button onClick={() => setNoteDialogOpen(true)}>
            {t('content.tracking.priorities.add_note')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
