import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import _ from 'lodash';
import { ReactComponent as Circle } from './legends/CirclePath.svg';
import { ReactComponent as Diamond } from './legends/DiamondPath.svg';
import { ReactComponent as Hexagon } from './legends/HexagonPath.svg';
import { ReactComponent as Pentagon } from './legends/PentagonPath.svg';
import { ReactComponent as Square } from './legends/SquarePath.svg';
import { ReactComponent as Triangle } from './legends/TrianglePath.svg';
import { useTranslation } from 'react-i18next';
const SHAPES_BY_NAME = {
    circle: (color) => _jsx(Circle, { color: color }),
    diamond: (color) => _jsx(Diamond, { color: color }),
    hexagon: (color) => _jsx(Hexagon, { color: color }),
    pentagon: (color) => _jsx(Pentagon, { color: color }),
    square: (color) => _jsx(Square, { color: color }),
    triangle: (color) => _jsx(Triangle, { color: color }),
};
export function PriorityToggle({ name, color, shape, visible, toggle, }) {
    const { t } = useTranslation();
    const shapeEl = useMemo(() => {
        const fn = _.get(SHAPES_BY_NAME, shape, () => _jsx(_Fragment, {}));
        return fn(color);
    }, [color, shape]);
    return (_jsxs(Stack, Object.assign({ direction: "row", spacing: 2, alignItems: "center", className: "priority-toggle", whiteSpace: "nowrap", paddingRight: 4 }, { children: [shapeEl, _jsx(Typography, Object.assign({ variant: "body2" }, { children: name })), _jsx(Button, Object.assign({ name: name, color: "primary", variant: "text", onClick: toggle }, { children: visible
                    ? t('content.patient_chart.hide')
                    : t('content.patient_chart.show') }))] })));
}
