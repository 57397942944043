import { createContext, useContext, useMemo } from 'react';
import {
  CommonDataContext,
  Patient,
  PatientPriority,
  SymptomPriority,
  useMyPatient,
  useMyPatientPriorities,
} from 'ecarepd-shared-utilities';

import _ from 'lodash';
import _fp from 'lodash/fp';

export interface DataContextProps {
  patient?: Patient;
  patientPriorities?: PatientPriority;
  patientSymptomPriorities: SymptomPriority[];
}

export const DataContext = createContext<DataContextProps>({
  patientSymptomPriorities: [],
});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.

  const patient = useMyPatient();
  const patientPriorities = useMyPatientPriorities(patient);

  const { symptomPrioritiesById } = useContext(CommonDataContext);
  const patientSymptomPriorities = useMemo(() => {
    const priorityIDs = patientPriorities?.properties.priorityIDs || [];
    return _fp.flow([
      _fp.map((id: string) => _.get(symptomPrioritiesById, id)),
      _fp.compact,
    ])(priorityIDs);
  }, [patientPriorities, symptomPrioritiesById]);

  const value = useMemo<DataContextProps>(
    () => ({ patient, patientPriorities, patientSymptomPriorities }),
    [patient, patientPriorities, patientSymptomPriorities]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
