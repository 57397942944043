import React, { PropsWithRef, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  PatientService,
  useApiCallWithMessageBar,
  useMyDailyJournal,
} from 'ecarepd-shared-utilities';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Formik,
  Form,
  Field,
  FieldProps,
  FormikProps,
  useFormikContext,
  FormikHelpers,
} from 'formik';
import { useDataContext } from '../../contexts/DataContext';

export interface DailyJournalProps {
  onClickInfo: () => void;
}

export default function DailyJournal({
  onClickInfo,
}: PropsWithRef<DailyJournalProps>): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useDataContext();
  const journal = useMyDailyJournal();
  const formRef = useRef<
    FormikProps<{
      entry: string;
    }>
  >(null);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (entry: string) =>
      PatientService.assertJournalEntry(patient!, entry),
    success: {
      message: journal
        ? t('home.journal.successUpdate')
        : t('home.journal.successSubmit'),
    },
    failure: {
      message: journal
        ? t('home.journal.failureUpdate')
        : t('home.journal.failureSubmit'),
    },
  });

  useEffect(() => {
    if (!formRef.current?.dirty) {
      formRef.current?.resetForm({
        values: { entry: journal?.properties.entry || '' },
      });
    }
  }, [journal]);

  const handleFormikSubmit = useCallback(
    (
      {
        entry,
      }: {
        entry: string;
      },
      {
        setSubmitting,
        resetForm,
      }: FormikHelpers<{
        entry: string;
      }>
    ) => {
      resetForm({ values: { entry } });
      onSubmit(entry).then(() => setSubmitting(false));
    },
    [onSubmit]
  );

  const DailyJournalHeader = () => (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="h5">{t('home.journal.header')}</Typography>
      <IconButton size="small" onClick={onClickInfo}>
        <InfoOutlinedIcon fontSize="small" color="primary" />
      </IconButton>
    </Stack>
  );

  const DailyJournalFormContent = () => {
    const { dirty, resetForm, isSubmitting } = useFormikContext();
    return (
      <Stack flexGrow={1} spacing={4}>
        <Stack flexGrow={1} position="relative">
          <Field name="entry">
            {({ field }: FieldProps) => (
              <TextField multiline variant="outlined" fullWidth {...field} />
            )}
          </Field>
          {dirty && (
            <Box position="absolute" right={-6} top={-6}>
              <IconButton onClick={() => resetForm()}>
                <CancelIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
        <Stack justifyContent="center">
          <Button
            type="submit"
            disabled={isSubmitting || !dirty}
            variant="outlined"
          >
            {journal ? t('home.journal.update') : t('home.journal.submit')}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ entry: '' }}
      onSubmit={handleFormikSubmit}
    >
      <Form className="formik-flex">
        <Stack spacing={2}>
          <DailyJournalHeader />
          <Typography>{t('home.journal.subheader')}</Typography>
          <DailyJournalFormContent />
        </Stack>
      </Form>
    </Formik>
  );
}
