import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiButton'] = {
  outlined: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: 'large',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.5)',
    border: `2px solid ${COLOURS.Primary}`,
    width: 'fit-content',
    alignSelf: 'center',
    minWidth: '250px',
    ':disabled': {
      border: `2px solid ${COLOURS.Disabled}`,
    },
    ':hover': {
      border: `2px solid ${COLOURS.Primary}`,
    },
  },
};

export const MuiButton = {
  styleOverrides: styleOverrides,
};
