import { createTheme, ThemeOptions } from '@mui/material';
import { theme as baseTheme } from 'ecarepd-shared-utilities';
import { MuiButton } from './button';
import { MuiCard } from './card';
import { MuiContainer } from './container';
import { MuiDialog, MuiDialogTitle } from './dialog';
import { MuiList, MuiListItem } from './list';
import { MuiTabs } from './tabs';
import { MuiTypography } from './typography';

const updates: ThemeOptions = {
  components: {
    MuiButton,
    MuiCard,
    MuiContainer,
    MuiDialog,
    MuiDialogTitle,
    MuiList,
    MuiListItem,
    MuiTabs,
    MuiTypography,
  },
};

export const patientTheme = createTheme(baseTheme, updates);
