import { UnderConstruction } from '../components/UnderConstruction';
import image from '../assets/Under Construction/Resources.png';

export default function CareTeam(): JSX.Element {
  return (
    <UnderConstruction
      titleId="under_construction.title.resources"
      image={image}
    />
  );
}
