import { Box, Button, Dialog, DialogContent, Tab, Tabs } from '@mui/material';
import {
  useState,
  PropsWithRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogTitleWithClose,
  Patient,
  PatientService,
  UpdatePatientProps,
  useApiCallWithMessageBar,
} from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';
import { Circle } from '@mui/icons-material';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import Tab6 from './Tab6';

export interface TutorialProps {
  open: boolean;
  onClose: () => void;
  openCarePriorities: (v: boolean) => void;
}

enum TabValues {
  Intro,
  InformationIntro,
  ContactForm,
  YearsForm,
  PrioritiesIntro,
  SkippedPriorities,
}

const MINIMUM_YEAR = 1900;

export default function Tutorial({
  onClose,
  open,
  openCarePriorities,
}: PropsWithRef<TutorialProps>): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);
  const [value, setValue] = useState(TabValues.Intro);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState<'F' | 'M' | ''>('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [diagnosticYear, setDiagnosticYear] = useState('');
  const [allowContact, setAllowContact] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (p: Patient, patientData: UpdatePatientProps) =>
      PatientService.updatePatient(p, patientData),
    success: {
      message: t('content.tutorial.success_submit'),
    },
    failure: {
      message: t('content.tutorial.failure_submit'),
    },
  });

  useEffect(() => {
    if (patient?.properties) {
      setFirstname(patient.properties.firstname);
      setLastname(patient.properties.lastname);
    }
  }, [patient]);

  const handleSubmit = useCallback(() => {
    if (!patient?.id) {
      return;
    }

    if (gender !== 'M' && gender !== 'F') {
      return;
    }

    const patientData: UpdatePatientProps = {
      firstname,
      lastname,
      phone,
      gender,
      dateOfBirth: new Date(Number(yearOfBirth), 0, 1).toISOString(),
      diagnosticDate: new Date(Number(diagnosticYear), 0, 1).toISOString(),
      allowContact,
      showTutorial: false,
    };

    onSubmit(patient, patientData).then(onClose);
  }, [
    onClose,
    onSubmit,
    firstname,
    lastname,
    phone,
    gender,
    yearOfBirth,
    diagnosticYear,
    allowContact,
    patient,
  ]);

  const onTabChange = (_event: any, newValue: string | number) => {
    setValue(Number(newValue));
  };

  const handleClose = () => {
    onClose();
  };

  const onButtonClick = () => {
    setHasErrors(false);

    if (value === TabValues.PrioritiesIntro) {
      setValue(value + 1);
      openCarePriorities(true);
    } else if (value === TabValues.SkippedPriorities) {
      handleSubmit();
    } else if (
      value === TabValues.ContactForm &&
      (!firstname || !lastname || !phone)
    ) {
      setHasErrors(true);
    } else if (
      value === TabValues.YearsForm &&
      (!yearOfBirth ||
        !diagnosticYear ||
        !gender ||
        Number(yearOfBirth) < MINIMUM_YEAR ||
        Number(diagnosticYear) < MINIMUM_YEAR)
    ) {
      setHasErrors(true);
    } else {
      setValue(value + 1);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} className="patient-tutorial">
      <DialogTitleWithClose onClose={handleClose}>
        {t('content.tutorial.header')}
      </DialogTitleWithClose>

      <DialogContent>
        <Tab1 value={value} />
        <Tab2 value={value} />
        <Tab3
          tabValue={value}
          firstname={firstname}
          setFirstname={setFirstname}
          lastname={lastname}
          setLastname={setLastname}
          phone={phone}
          hasErrors={hasErrors}
          setPhone={setPhone}
          allowContact={allowContact}
          setAllowContact={setAllowContact}
        />
        <Tab4
          tabValue={value}
          gender={gender}
          setGender={setGender}
          hasErrors={hasErrors}
          diagnosticYear={diagnosticYear}
          setDiagnosticYear={setDiagnosticYear}
          yearOfBirth={yearOfBirth}
          setYearOfBirth={setYearOfBirth}
          minimumYear={MINIMUM_YEAR}
        />
        <Tab5 value={value} onChange={onTabChange} />
        <Tab6 value={value} />
      </DialogContent>

      <Box display="flex">
        <Tabs onChange={onTabChange} value={value} aria-label="tabs">
          <Tab icon={<Circle />} value={TabValues.Intro} />
          <Tab
            disabled={value < TabValues.InformationIntro}
            icon={<Circle />}
            value={TabValues.InformationIntro}
          />
          <Tab
            disabled={value < TabValues.ContactForm}
            icon={<Circle />}
            value={TabValues.ContactForm}
          />
          <Tab
            disabled={value < TabValues.YearsForm}
            icon={<Circle />}
            value={TabValues.YearsForm}
          />
          <Tab
            disabled={value < TabValues.PrioritiesIntro}
            icon={<Circle />}
            value={TabValues.PrioritiesIntro}
          />
          <Tab
            disabled={value < TabValues.SkippedPriorities}
            icon={<Circle />}
            value={TabValues.SkippedPriorities}
          />
        </Tabs>
        <Button onClick={onButtonClick}>{t('common.continue')}</Button>
      </Box>
    </Dialog>
  );
}
