export const COLOURS = {
    Primary: '#428be5',
    Secondary: '#1B7FD1',
    LightBlueBackground: '#EAF2FB',
    DarkBlue: '#28456E',
    RoyalBlue: '#055CC0',
    YetAnotherBlue: '#0134a0',
    CardBorderColor: '#4a90e226',
    CardBackgroundColor: '#4a90e280',
    DarkGrey: '#58585B',
    CloseToDarkGrey: '#55565A',
    DarkerGrey: '#282728',
    TableBorderGrey: '#C6C6C6',
    OddRowBackground: '#efefef',
    Disabled: '#979797',
    Black: '#000000',
    White: '#ffffff',
    Red: '#d32f2f',
    Chart: [
        // From the document titled 'eCarePD - Graph Line Colors.pdf'
        '#163198',
        '#0096BF',
        '#006D21',
        '#F7CC1B',
        '#FF7000',
        '#A7210C',
        '#E6148E',
        '#5F2293',
        '#602B21',
        '#BA9346',
        '#84DA99',
        '#003651', // Prussian
    ],
};
