export const PATH_CARE_FINDER = '/careFinder';
export const PATH_CARE_TEAM = '/careTeam';
export const PATH_HOME = '/';
export const PATH_HISTORY = '/history';
export const PATH_JOURNAL = '/journal';
export const PATH_RESOURCES = '/resources';
export const PATH_TRACKING = '/tracking';
export const PATH_SETTINGS = '/settings';
export const PATH_EDITINFO = '/settings/editInfo';

export const STORAGE_CURRENT_TAB = 'current-tab';
export const STORAGE_HISTORY_ENTRY_COUNT = 'history-entry-count';
