import { PropsWithChildren, useMemo, useRef } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Stack } from '@mui/material';

import { useConnectionState } from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import { Footer, FooterOptions } from 'ecarepd-shared-utilities';

import htmlReactParser from 'html-react-parser';

import {
  Authenticating,
  Connecting,
  Disconnected,
  Disconnecting,
  NotImplemented,
} from 'ecarepd-shared-utilities';
import Header from '../components/Header';
import TabBar from '../components/TabBar';
import Content from './Content';
import Donate from '../components/home/Donate';

function InternalMain(): JSX.Element {
  const connectionState = useConnectionState();
  const tabRef = useRef<any>(null);

  return useMemo<JSX.Element>(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return (
          <>
            <Disconnected />
            <Donate />
          </>
        );

      case ConnectionService.ConnectionState.DISCONNECTING:
        return <Disconnecting />;

      case ConnectionService.ConnectionState.CONNECTING:
      case ConnectionService.ConnectionState.CONNECTING_FROM_TOKEN:
        return <Connecting />;

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return (
          <Stack direction="row" flex={1} overflow="hidden">
            <Stack ref={tabRef}>
              <TabBar />
            </Stack>
            <Stack padding={4} flex={1} overflow="auto">
              <Content />
            </Stack>
          </Stack>
        );

      default:
        return <NotImplemented />;
    }
  }, [connectionState]);
}

function RouteElement({ children }: PropsWithChildren<any>): JSX.Element {
  const footerOptions: FooterOptions = {
    formatHtml: htmlReactParser,
  };
  return (
    <Stack height="100%">
      <Header />
      {children}
      <Footer options={footerOptions} />
    </Stack>
  );
}

export default function Main(): JSX.Element {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/oidc"
          element={<RouteElement children={<Authenticating />} />}
        />

        <Route
          path="/*"
          element={<RouteElement children={<InternalMain />} />}
        />

        <Route
          path="*"
          element={<RouteElement children={<Navigate to="/" />} />}
        />
      </Routes>
    </Router>
  );
}
