import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import htmlReactParser from 'html-react-parser';
import { PropsWithRef, useContext, useMemo } from 'react';
import { CommonDataContext } from 'ecarepd-shared-utilities';
import _ from 'lodash';

export interface DoneProps {
  priority: string;
}
export function Done({ priority }: PropsWithRef<DoneProps>): JSX.Element {
  const { t } = useTranslation();
  const { symptomPrioritiesById } = useContext(CommonDataContext);

  const symptom = useMemo(
    () => _.get(symptomPrioritiesById, [priority, 'properties', 'name']),
    [priority, symptomPrioritiesById]
  );

  const headerTitle = useMemo(
    () =>
      t('call_request.done.header', {
        symptom,
        when: t('call_request.done.when'),
      }),
    [symptom, t]
  );

  return (
    <Stack spacing={4} className="call-request-content">
      <Typography variant="body1">{headerTitle}</Typography>
      <Typography variant="body1">
        {htmlReactParser(t('call_request.done.sub_header'))}
      </Typography>
    </Stack>
  );
}
