import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PriorityToggle } from './PriorityToggle';
import { CustomDotShapesOrder } from './CustomDot';
import { NotImplemented } from '../NotImplemented';
import _ from 'lodash';
import { CommonDataContext } from '../../contexts';
import { COLOURS } from '../../theme';
export function ChartFooter({ priorityIds, visiblePriorities, toggleVisiblePriority, carePriorityId, }) {
    const { t } = useTranslation();
    const { symptomPrioritiesById } = useContext(CommonDataContext);
    return (_jsxs(Stack, Object.assign({ flexGrow: "0", direction: "row", paddingRight: 10, paddingLeft: 10, className: "patient-chart-footer" }, { children: [_jsxs(Stack, Object.assign({ direction: "column", width: "70%" }, { children: [_jsx(Typography, Object.assign({ variant: "h4", color: "primary" }, { children: t(carePriorityId || 'content.patient_chart.care_priorities') })), _jsx(Stack, Object.assign({ direction: "row", paddingLeft: 4, spacing: 0, flexWrap: "wrap", width: "50%" }, { children: _.map(priorityIds, (id, index) => {
                            const color = COLOURS.Chart[index % COLOURS.Chart.length];
                            const shape = CustomDotShapesOrder[index % CustomDotShapesOrder.length];
                            const name = _.get(symptomPrioritiesById, [
                                id,
                                'properties',
                                'name',
                            ]);
                            return (_jsx(Fragment, { children: _jsx(PriorityToggle, { name: name, color: color, shape: shape, visible: visiblePriorities.has(id), toggle: () => toggleVisiblePriority(id) }) }, id));
                        }) }))] })), _jsxs(Stack, Object.assign({ direction: "column", width: "50%" }, { children: [_jsx(Typography, Object.assign({ variant: "h4", color: "primary" }, { children: t('content.patient.fitbit_information.title') })), _jsx(NotImplemented, {})] }))] })));
}
