import { Paper, Stack, Typography } from '@mui/material';
import { PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface UnderConstructionProps {
  titleId: string;
  image: string;
}

export function UnderConstruction({
  titleId,
  image,
}: PropsWithRef<UnderConstructionProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack
      flexGrow="1"
      paddingTop={4}
      alignItems="center"
      spacing={16}
      className="under_construction"
    >
      <Stack spacing={4} alignItems="center">
        <Typography variant="h4" align="center">
          {t(titleId)}
        </Typography>

        <Typography variant="body1" align="center">
          {t('under_construction.message')}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h5">{t('under_construction.building')}</Typography>

        <Paper>
          <Stack padding={4}>
            <img src={image} alt={titleId} />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
}
