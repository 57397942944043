import { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useMyPatient } from 'ecarepd-shared-utilities';
import CarePriorities from '../components/home/CarePriorities';
import DailyJournal from '../components/home/DailyJournal';
import Welcome from '../components/home/Welcome';
import Tutorial from './tutorial/Tutorial';
import { JournalDialog } from '../components/dialog';
import CarePrioritiesLanding from './carePriorities/CarePrioritiesLanding';
import { DataContext } from '../contexts/DataContext';

export default function Home(): JSX.Element {
  const patient = useMyPatient();
  const [isTutorialDialogOpen, setIsTutorialDialogOpen] = useState(false);
  const [isJournalDialogOpen, setIsJournalDialogOpen] = useState(false);
  const [openPriorities, setOpenPriorities] = useState(false);
  const [initialSetup, setInitialSetup] = useState(false);
  const { patientPriorities: selectedPriorities } = useContext(DataContext);

  useEffect(() => {
    if (patient?.properties?.showTutorial) {
      setIsTutorialDialogOpen(true);
    }
  }, [patient]);

  const handleOpenPriorities = (isInitialSetup: boolean) => {
    setInitialSetup(isInitialSetup);
    setOpenPriorities(true);
  };

  const handleClosePriorities = () => {
    setOpenPriorities(false);
  };

  return (
    <Stack flexGrow="1" direction="row" spacing={4}>
      <Tutorial
        onClose={() => setIsTutorialDialogOpen(false)}
        open={isTutorialDialogOpen}
        openCarePriorities={handleOpenPriorities}
      />
      <CarePrioritiesLanding
        initialSetup={initialSetup}
        onClose={handleClosePriorities}
        open={openPriorities}
        oldPriorities={selectedPriorities}
      />
      <JournalDialog
        onClose={() => setIsJournalDialogOpen(false)}
        open={isJournalDialogOpen}
      />
      <Welcome patient={patient} />
      <CarePriorities handleOpen={handleOpenPriorities} />
      <Box display="flex" flexGrow={3} flexBasis={0} height="100%">
        <DailyJournal onClickInfo={() => setIsJournalDialogOpen(true)} />
      </Box>
    </Stack>
  );
}
