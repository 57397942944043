import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material';
import {
  useContext,
  PropsWithRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonDataContext,
  Patient,
  PatientService,
  useApiCallWithMessageBar,
  COLOURS,
  PatientPriority,
  DialogTitleWithClose,
} from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';
import _ from 'lodash';
import _fp from 'lodash/fp';
import SelectCarePriorities from './SelectCarePriorities';
import CarePrioritiesHome from './CarePrioritiesHome';
import CarePrioritiesActions from './CarePrioritiesActions';
import CarePriorityCard from './CarePriorityCard';
import CarePrioritiesTooltip from './CarePrioritiesTooltip';

export interface CarePrioritiesLandingProps {
  open: boolean;
  onClose: () => void;
  oldPriorities?: PatientPriority;
  initialSetup?: boolean;
}

enum TabValues {
  Home,
  SelectPriorities,
}

export default function CarePrioritiesLanding({
  onClose,
  open,
  oldPriorities,
  initialSetup = false,
}: PropsWithRef<CarePrioritiesLandingProps>): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);
  const { symptomPriorities } = useContext(CommonDataContext);
  const [selectedPriorities, setSelectedPriorities] = useState<Array<string>>(
    []
  );
  const [tabValue, setTabValue] = useState(TabValues.Home);
  const [typeIndex, setTypeIndex] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipHeader, setTooltipHeader] = useState('');

  useEffect(() => {
    if (oldPriorities?.properties?.priorityIDs) {
      setSelectedPriorities(oldPriorities?.properties?.priorityIDs);
    }
  }, [oldPriorities]);

  const handleClose = () => {
    onClose();
  };

  const tooltipClick = useCallback((text: string, header: string) => {
    setTooltipHeader(header);
    setTooltipText(text);
    setTooltipOpen(true);
  }, []);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (p: Patient) =>
      PatientService.setPatientPriorities(p, selectedPriorities),
    success: {
      message: t('content.care_priorities.success_submit'),
    },
    failure: {
      message: t('content.care_priorities.failure_submit'),
    },
  });

  const handleSubmit = useCallback(() => {
    if (!patient?.properties.patientID) {
      return;
    }

    onSubmit(patient).then(onClose);
  }, [onClose, onSubmit, patient]);

  const groupings = useMemo(
    () =>
      _.reduce(
        symptomPriorities,
        // TODO: set proper type
        (group: any, a: { properties: { categoryName: string } }) => {
          group[a.properties.categoryName] =
            group[a.properties.categoryName] || [];
          group[a.properties.categoryName].push(a);
          return group;
        },
        {}
      ),
    [symptomPriorities]
  );

  const alphabeticalGroupings = useMemo(
    () =>
      _fp.flow([
        _fp.keys,
        _fp.sortBy(_fp.identity),
        _fp.map((key: string) => groupings[key]),
      ])(groupings),
    [groupings]
  );

  const selectPriority = useCallback(
    (priorityID: string) => {
      if (selectedPriorities.includes(priorityID)) {
        setSelectedPriorities(
          selectedPriorities.filter((e) => e !== priorityID)
        );
      } else if (selectedPriorities.length < 4) {
        setSelectedPriorities((previousPriorities: string[]) => [
          ...previousPriorities,
          priorityID,
        ]);
      } else {
        tooltipClick(
          `${t(`content.care_priorities.maximum`)}`,
          `${t(`content.care_priorities.maximum_header`)}`
        );
      }
    },
    [selectedPriorities, tooltipClick, t]
  );

  const handleSelectPriorities = useCallback((newIndex: number) => {
    setTypeIndex(newIndex);
    setTabValue(TabValues.SelectPriorities);
  }, []);

  const selectedWithProps = useMemo(
    () =>
      _.reduce(
        selectedPriorities,
        // TODO: set proper type
        (selected: any, a: string, index: number) => {
          const found = _.find(symptomPriorities, (prop) => prop.id === a);
          selected[index] = found;
          return selected;
        },
        []
      ),
    [selectedPriorities, symptomPriorities]
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="update-care-priorities"
    >
      {tabValue === TabValues.Home && (
        <DialogTitle>{t('content.care_priorities.header')}</DialogTitle>
      )}
      {tabValue === TabValues.SelectPriorities && initialSetup && (
        <DialogTitle>{t('content.care_priorities.select')}</DialogTitle>
      )}
      {tabValue === TabValues.SelectPriorities && !initialSetup && (
        <DialogTitleWithClose onClose={() => setTabValue(TabValues.Home)}>
          {t('content.care_priorities.select')}
        </DialogTitleWithClose>
      )}

      <DialogContent>
        <CarePrioritiesHome
          tabValue={tabValue}
          alphabeticalGroupings={alphabeticalGroupings}
          handleClick={handleSelectPriorities}
        />

        <SelectCarePriorities
          value={tabValue}
          selectPriority={selectPriority}
          selectedPriorities={selectedPriorities}
          priorities={alphabeticalGroupings[typeIndex]}
          tooltipClick={tooltipClick}
        />
      </DialogContent>

      <DialogActions>
        <Stack flexDirection="column" width="100%">
          <Typography
            variant="h6"
            paddingLeft={4}
            color={COLOURS.Black}
            justifySelf="flex-start"
          >
            {t('content.care_priorities.current')}
            {!selectedWithProps.length && t('content.care_priorities.none')}
          </Typography>
          {selectedWithProps.length && (
            <Stack
              className="care-priority-card"
              flexGrow="1"
              flexDirection="row"
            >
              {_.map(selectedWithProps, (priority, index) => (
                <Fragment key={index}>
                  <CarePriorityCard
                    priority={priority}
                    handleClick={() => selectPriority(priority.id)}
                  />
                </Fragment>
              ))}
            </Stack>
          )}

          <Stack justifyContent="center" width="fill-available">
            <CarePrioritiesActions
              alphabeticalGroupings={alphabeticalGroupings}
              handleSubmit={handleSubmit}
              tabValue={tabValue}
              initialSetup={initialSetup}
              setTabValue={setTabValue}
              setTypeIndex={setTypeIndex}
              typeIndex={typeIndex}
            />
          </Stack>
        </Stack>
      </DialogActions>

      <CarePrioritiesTooltip
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        text={tooltipText}
        priority={tooltipHeader}
      />
    </Dialog>
  );
}
