import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSymptomPriorities, useSymptomPrioritiesById } from '../hooks';
import { CommonDataContext } from './CommonDataContext';
// Note: the provider can NOT be in the same file as the context. That causes a circular
// reference between the context itself and useSymptomPrioritiesById.
export function CommonDataContextProvider({ children, }) {
    const symptomPriorities = useSymptomPriorities();
    const symptomPrioritiesById = useSymptomPrioritiesById();
    const value = useMemo(() => ({
        symptomPriorities: symptomPriorities || [],
        symptomPrioritiesById,
    }), [symptomPriorities, symptomPrioritiesById]);
    return (_jsx(CommonDataContext.Provider, Object.assign({ value: value }, { children: children })));
}
