import { spacing } from './constants';
const styleOverrides = {
    paper: {
        borderRadius: 8,
        margin: spacing * 4,
        padding: spacing * 4,
    },
};
export const MuiPopover = {
    styleOverrides,
};
