import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Link as MuiLink, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hive-react-utils';
import { TextDialog } from './TextDialog';
import { HealthDefinesUsFr, HealthDefinesUsEn } from '../assets';
const DEFAULT_LABELS = {
    contactUs: 'footer.contact_us',
    contactEmail: 'footer.contact_email',
    privacyPolicyTitle: 'footer.privacy_policy_title',
    privacyPolicyText: 'footer.privacy_policy_text',
    privacyPolicy: 'footer.privacy_policy',
    termsOfServiceTitle: 'footer.terms_of_service_title',
    termsOfServiceText: 'footer.terms_of_service_text',
    termsOfService: 'footer.terms_of_service',
};
const DEFAULT_OPTIONS = {
    formatHtml: (t) => t,
    labels: DEFAULT_LABELS,
};
export function Footer({ options = DEFAULT_OPTIONS, }) {
    const { t } = useTranslation();
    const language = useCurrentLanguage();
    const labels = (options === null || options === void 0 ? void 0 : options.labels) || DEFAULT_LABELS;
    const logo = language === 'fr' ? _jsx(HealthDefinesUsFr, {}) : _jsx(HealthDefinesUsEn, {});
    const contactEmail = t(labels.contactEmail);
    const [infoTitle, setInfoTitle] = useState('');
    const [infoText, setInfoText] = useState('');
    const handlePolicyPrivacy = useCallback(() => {
        setInfoTitle(t(labels.privacyPolicyTitle));
        setInfoText(t(labels.privacyPolicyText));
    }, [t, labels]);
    const handleTermsOfService = useCallback(() => {
        setInfoTitle(t(labels.termsOfServiceTitle));
        setInfoText(t(labels.termsOfServiceText));
    }, [t, labels]);
    const handleCloseInfoDialog = () => {
        setInfoTitle('');
        setInfoText('');
    };
    return (_jsx("footer", { children: _jsxs(Paper, { children: [_jsx(TextDialog, { open: !!infoText, onClose: handleCloseInfoDialog, title: infoTitle, text: infoText, formatHtml: options.formatHtml }), _jsxs(Stack, Object.assign({ direction: "row", spacing: 1, padding: 1, paddingLeft: 2, paddingRight: 2, justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(MuiLink, Object.assign({ href: "https://www.macadamian.com", target: "_blank", display: "contents" }, { children: logo })), _jsxs(Stack, Object.assign({ direction: "row", justifyContent: "center", spacing: 2, flexGrow: 1 }, { children: [_jsx(MuiLink, Object.assign({ variant: "body2", onClick: handlePolicyPrivacy }, { children: t(labels.privacyPolicy) })), _jsx(MuiLink, Object.assign({ variant: "body2", onClick: handleTermsOfService }, { children: t(labels.termsOfService) }))] })), _jsx(MuiLink, Object.assign({ variant: "body2", href: contactEmail }, { children: _jsx(_Fragment, { children: t(labels.contactUs) }) }))] }))] }) }));
}
