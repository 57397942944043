import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, Link, Card } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import htmlReactParser from 'html-react-parser';
import { CallRequestDialog } from '../callRequest/CallRequestDialog';
import { ReactComponent as Note } from '../../assets/note.svg';
import { useNavigate } from 'react-router-dom';
import { PATH_TRACKING } from '../../content/constants';
import CarePrioritiesTooltip from '../../content/carePriorities/CarePrioritiesTooltip';
import { useState, PropsWithRef, useCallback, useContext } from 'react';
import { COLOURS, ScaleIcon } from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';

export interface PriorityProps {
  priority: any; //TODO: set proper type
}

function Priority({ priority }: PropsWithRef<PriorityProps>): JSX.Element {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipHeader, setTooltipHeader] = useState('');

  const latestResponse = priority.properties.latestResponse?.properties;

  const lastReportedDate = latestResponse
    ? moment(latestResponse.date2).format('MMMM Do, YYYY')
    : `${t('home.care_priorities.no_report')}`;

  const tooltipClick = useCallback((text: string, header: string) => {
    setTooltipHeader(header);
    setTooltipText(text);
    setTooltipOpen(true);
  }, []);
  // TODO: care tip?

  return (
    <Card variant="outlined" className="care-priorities-home">
      <Stack>
        <Typography
          variant="h5"
          paddingBottom={2}
          display="flex"
          align-items="center"
          gap={1}
        >
          <b>{priority.properties?.name}</b>
          {latestResponse?.note && (
            <Note
              onClick={() =>
                tooltipClick(
                  latestResponse.note,
                  t('content.history.care_priority_history.note_dialog.title', {
                    symptomName: priority.properties.name,
                  })
                )
              }
              color={COLOURS.Primary}
            />
          )}
        </Typography>
        <Button
          onClick={() =>
            tooltipClick(
              priority.properties.information,
              priority.properties.name
            )
          }
          variant="text"
        >
          {t('home.care_priorities.more_information')}
        </Button>
        <Typography variant="body2">
          {t('home.care_priorities.last_report')}
          <b>{lastReportedDate}</b>
        </Typography>
      </Stack>
      {latestResponse?.response > -1 && (
        <Stack justifyContent="center">
          <ScaleIcon response={latestResponse.response} highlighted={false} />
        </Stack>
      )}
      <CarePrioritiesTooltip
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        text={tooltipText}
        priority={tooltipHeader}
      />
    </Card>
  );
}

export interface CarePrioritiesProps {
  handleOpen: (v: boolean) => void;
}

export default function CarePriorities({
  handleOpen,
}: PropsWithRef<CarePrioritiesProps>): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientPriorities: selectedPriorities } = useContext(DataContext);
  const [showNeedHelp, setShowNeedHelp] = useState(false);

  return (
    <Stack flexBasis="0" flexGrow="4" spacing={2}>
      <CallRequestDialog
        open={showNeedHelp}
        onClose={() => setShowNeedHelp(false)}
      />

      <Stack
        alignItems="baseline"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h5">{t('home.care_priorities.header')}</Typography>
        <Button variant="text" onClick={() => handleOpen(false)}>
          {t('home.care_priorities.change')}
        </Button>
      </Stack>
      <Stack spacing={4}>
        {selectedPriorities?.properties?.priorities &&
        !_.isEmpty(selectedPriorities.properties?.priorities) ? (
          <>
            {_.map(selectedPriorities.properties.priorities, (priority) => (
              <div key={priority.id}>
                <Priority priority={priority} />
              </div>
            ))}
            <Button variant="outlined" onClick={() => navigate(PATH_TRACKING)}>
              {t('home.care_priorities.complete')}
            </Button>
          </>
        ) : (
          <>
            <Typography>
              {htmlReactParser(t('home.care_priorities.no_priorities'))}
            </Typography>
            <Button variant="outlined" onClick={() => handleOpen(false)}>
              {t('home.care_priorities.select')}
            </Button>
          </>
        )}
        <Link
          alignSelf="center"
          underline="none"
          onClick={() => setShowNeedHelp(true)}
        >
          {t('home.care_priorities.help')}
        </Link>
      </Stack>
    </Stack>
  );
}
