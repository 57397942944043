import { PrivacyPolicy_en as privacy_policy_text } from './PrivacyPolicy';
import { TermsOfService_en as terms_of_service_text } from './TermsOfService';

const APP_TITLE = 'eCarePD';

export const en = {
  app: {
    title: APP_TITLE,
  },
  common: {
    cancel: 'Cancel',
    continue: 'Continue',
    success: 'Success',
    failure: 'Failure',
    back: 'Back',
    next: 'Next',
    n_a: 'N/A',
    done: 'Done',
    required_field: 'Required field',
  },
  footer: {
    contact_us: 'Need help? Contact us.',
    contact_email: 'mailto:ecare-pd@toh.ca',
    privacy_policy_title: 'Privacy Policy',
    privacy_policy: 'privacy policy',
    privacy_policy_text,
    terms_of_service_title: 'Terms of Service',
    terms_of_service: 'terms of service',
    terms_of_service_text,
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    logout: 'Sign Out',
    settings: 'Settings',
  },
  home: {
    care_priorities: {
      header: 'Your Care Priorities',
      change: 'Change My Care Priorities',
      last_report: 'Last report: ',
      no_report: 'not reported yet',
      speech: 'Speech',
      freezing: 'Freezing',
      memory: 'Memory',
      sleep: 'Sleep',
      complete: 'Complete Care Survey',
      help: 'Need to talk to a Clinician?',
      more_information: 'More information',
      select: 'Select your care priorities',
      no_priorities:
        "Having personal goals and tracking your care priorities help you assess how you are feeling and maintaining your quality of life can make a big difference when it comes to living well with Parkinson's.<br><br>eCare PD can assist you to achieve your goals by focussing on your personal care priorities and providing resources and tips to help you.",
    },
    journal: {
      header: 'Daily Journal',
      subheader:
        "How are you doing today? What's going well? Are you having any issues or concerns?",
      submit: 'Send to Journal',
      update: 'Update Journal',
      successUpdate: 'Daily Journal updated',
      successSubmit: 'Daily Journal created',
      failureUpdate: 'Failed to update Journal',
      failureSubmit: 'Failed to create Journal',
    },
    welcome: {
      header: 'Welcome {{firstName}}!',
      subheader:
        "You've been doing pretty well recently, we hope that that continues.",
      exercise_header:
        'Have you thought about signing up for an exercise program?',
      exercise_subheader:
        "There is evidence that exercise may hold specific benefits for people with Parkinson's in staying active and relatively limber, and improving balance and motor coordination.",
    },
  },
  not_implemented: 'Not implemented',
  tab_bar: {
    care_finder: 'Care Finder',
    care_team: 'Care Team',
    history: 'History',
    home: 'Home',
    journal: 'Journal',
    resources: 'Resources',
    tracking: 'Tracking',
  },
  content: {
    journal: {
      dialog: {
        title: "It's Your Journal",
        content:
          'Your journal is your place to keep personal notes and thoughts that can relate to your PD journey, or anything else.\n\nThese entries are private to your account, and will not be shared with your care team, or any one else with acces to the eCare PD platform.',
      },
      carePriority: 'Care Priority',
    },
    care_priorities: {
      header: 'Update Your Care Priorities',
      subheader:
        'Take your time to identify 3 or 4 care priorities that you want to focus on at this time. <br>Which care priorities affect your quality of life and well being at this time? <br>What care priorities would like to monitor over time, receiving tips when needed, to help improve your well being?',
      current: 'Your current care priorities: ',
      none: 'none selected.',
      // Capitilized to match backend, do not change
      Physical: 'Physical Health',
      Mental: 'Mental Health',
      Lifestyle: 'Lifestyle & Social Life',
      Other: 'Other Care Priorities',
      select: 'Select your Care Priorities',
      maximum:
        'You have already selected the maximum of 4 Care Priorities for tracking. <br><br>It is important to focus on a few top priorities at a time.<br><br>You can go back and modify your selection to ensure you are focussing on your top issues.',
      maximum_header: 'Maximum Reached',
      success_submit: 'Care Priorities updated',
      failure_submit: 'Failed to update Care Priorities',
    },
    patient: {
      fitbit_information: {
        title: 'FitBit Tracker Information',
      },
    },
    patient_chart: {
      title: 'Care Priority Recent History',
      display_period: 'Display Period',
      care_priorities: 'Your Care Priorities',
      show: 'Show',
      hide: 'Hide',
      last_month: 'Last Month',
      last_week: 'Last Week',
      last_ten_days: 'Last 10 days',
      last_five_days: 'Last 5 days',
    },
    history: {
      care_priority_history: {
        title: 'Care Priority History',
        how_you_doing: "Here's how you've been doing over the past",
        recent_tips: 'Recent Care Tips...',
        tip_help: 'Does this help?',
        full_tip_sheet_link: 'Tap here to see the full tip sheet.',
        number_of_entries: {
          last_five: 'Last 5 Entries',
          last_twenty: 'Last 20 Entries',
          last_fifty: 'Last 50 Entries',
          all: 'All Entries',
        },
        note_dialog: {
          title: 'Personal Note - {{symptomName}}',
          please_note:
            'Please note that the care priority notes will be shared with you care team members.',
          add_note: 'Add Note',
        },
      },
    },
    tracking: {
      title: 'Care Priority Tracking Survey',
      sub_title:
        'Complete the following survey to monitor your progress with the following care priorities, think about how you have been feeling regarding the care priority since your last review.',
      actions: {
        update: 'Update Care Priorities',
      },
      priorities: {
        more_info: 'Tap here to see more information',
        add_note: 'Add personal note',
        care_tip_title: '{{symptom}} Care Tip',
        more_about: 'More About {{symptom}}',
      },
    },
    tutorial: {
      header: 'Welcome to eCare PD',
      tab1: "Parkinson's is a complex disease and everyone experiences it differently. <br><br> eCare PD is a platform that allows you to monitor your care priorities over time, manage your personal goals of care by providing tailored care tips and personalized care resources.",
      tab2: "In order to get a good start with eCare PD we have a few questions to get an understanding of where you are in your Parkinson's journey. <br><br>This information will be used to help direct the most relevant information and services to you.",
      first_name: 'What is your first name?',
      first_name_placeholder: 'Please enter your first name',
      last_name: 'What is your last name?',
      last_name_placeholder: 'Please enter your last name',
      telephone: 'What is your mobile phone number?',
      telephone_placeholder: 'Please enter your phone number',
      allow_contact: 'Allow Care Team to contact using text messaging',
      tab4: 'In order to provide the most relevant information, tips and services the following information is required.',
      diagnosed_date: 'When were you diagnosed with PD?',
      diagnosed_date_placeholder: 'Please enter your diagnosis year',
      birth_date: 'What is your date of birth?',
      birth_date_placeholder: 'Please enter the year of your birth',
      gender: 'What is your gender?',
      please_select: 'Please select',
      male: 'Male',
      female: 'Female',
      tab5: "Having personal goals and tracking your care priorities help you assess how you are feeling and maintaining your quality of life can make a big difference when it comes to living well with Parkinson's. eCare PD can assist you to achieve your goals by focusing on your personal care priorities and providing resources and tips to help you.",
      skip: 'Skip',
      tab6: 'Your eCare PD experienced can be further enhanced by using a connected health tracker, and allowing eCare PD to see your status will help to monitor your movement and sleep quality. <br><br>You can connect your wearable device at any time from the home screen.',
      success_submit: 'Patient data updated',
      failure_submit: 'Failed to update patient data',
    },
  },
  call_request: {
    title: 'Need to talk to a Clinician?',
    priority: {
      header:
        'If you need help, one of your Care Team members can contact you to help you with your concerns specific to your Care Priorities.',
      please_select:
        'Please select which Care Priority you are having difficulties with:',
    },
    comment: {
      header:
        'Would you like to send a comment or description of the issues you are experiencing to help your care team to understand and focus the conversation to be had?',
    },
    done: {
      header:
        'A member of your Care Team will contact you for a 10-minute call to discuss your concerns with {{symptom}} within the next week.',
      sub_header:
        'For assistance, you can contact us by email at <a href="mailto:ecarepd@toh.ca">ecarepd@toh.ca</a>',
      when: 'TODO: get next review date from backend',
    },
  },
  signin: {
    please_sign_in: 'Please sign in...',
  },
  donate: {
    header: 'Donate to Parkinson Research in Ottawa',
    text: "Your gift today will support the many Parkinson research projects taking place in Ottawa that will help you and your family to live better with Parkinson's disease.",
    donate_now: 'Donate Now',
  },
  settings: {
    your_info: {
      title: 'Your Info',
      name: 'Name',
      email: 'Email Address',
      diagnosis_date: 'Diagnosis Date',
      dob: 'Date of Birth',
      gender: 'Gender',
      edit_info: {
        title: 'Update Your Info',
        first_name: 'First Name',
        last_name: 'Last Name',
        phone_number: 'Phone Number',
        allow_sms: 'Allow Care Team to contact using text messaging',
        button: 'Edit Info',
        save: 'Save Updates',
        diagnosis_date: 'Diagnosis Date',
        email: 'Email Address *',
        email_subtext:
          '* Email Address is not modifiable, if you need to change it please contact us at ecarepd@toh.ca',
      },
    },
    reset_password: 'Reset Password',
    delete_account1: {
      button: 'Delete Account',
      title: 'Delete Account',
      yes_delete: 'Yes, delete my account',
      message1: 'Are you sure you want to delete you eCarePD account?',
      message2:
        'Your referring Doctor will be notified that you have chosen to leave the eCarePD program.',
    },
    delete_account2: {
      button: 'Delete Account',
      title: 'Delete Account',
      yes_delete: "Yes, I'm sure",
      message1: 'Are you really sure you want to delete your eCarePD account?',
      message2:
        'Once complete, you will not be able to access eCarePD using these credentials.',
    },
  },
  under_construction: {
    title: {
      care_team: 'Care Team',
      care_finder: 'Care Finder',
      resources: 'Resources',
    },
    message:
      'Sorry, this service is not currently available, it is currently in development, please check back.',
    building: "What we're building....",
  },
};
