import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ResponsiveContainer, ComposedChart, CartesianGrid, Tooltip, Line, XAxis, YAxis, } from 'recharts';
import { ScaleSvgImage } from '../ScaleSvgImage';
import { CustomDotShapesOrder, CustomDot } from './CustomDot';
import _ from 'lodash';
import moment from 'moment';
import { ChartLegend } from './ChartLegend';
import { COLOURS, spacing } from '../../theme';
export function ChartBody({ stats, priorityIds, visiblePriorites, }) {
    return (
    // TODO: Figure out why this takes more space that it should vertically.
    // NOTE: The Responsive container might not resize propertly with React@18. Hopefully, by the time the
    // product is release, this will be fixed. Otherwise, we might have to use React@17
    // https://github.com/recharts/recharts/issues/2831
    _jsx(ResponsiveContainer, { children: _jsxs(ComposedChart, Object.assign({ data: stats, margin: {
                top: spacing * 3,
                bottom: spacing * 2,
                left: spacing * -6,
                right: spacing * 4,
            } }, { children: [_jsx(CartesianGrid, { horizontal: true, vertical: false }), _jsx(Tooltip, { content: (props) => _jsx(ChartLegend, Object.assign({}, props)) }), _.map(priorityIds, (id, index) => {
                    const hide = !visiblePriorites.has(id);
                    const color = COLOURS.Chart[index % COLOURS.Chart.length];
                    const shape = CustomDotShapesOrder[index % CustomDotShapesOrder.length];
                    return (_jsx(Line, { hide: hide, r: 6, isAnimationActive: false, dataKey: `${id}.value`, fill: color, stroke: color, strokeWidth: 2, activeDot: false, dot: _jsx(CustomDot, { shape: shape }) }, id));
                }), _jsx(XAxis, { dataKey: "timestamp", tickMargin: 16, tickFormatter: (value) => moment.unix(value).format('MMM DD') }), _jsx(YAxis, { tickCount: 5, tick: (props) => ScaleSvgImage(Object.assign(Object.assign({}, props), { width: 32, height: 32 })) })] })) }));
}
