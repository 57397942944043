import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

export default function Welcome(patient: any): JSX.Element {
  const [firstName, setFirstName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (patient?.patient?.properties) {
      setFirstName(patient.patient.properties.firstname);
    }
  }, [patient]);

  return (
    <Stack flexBasis="0" flexGrow="2" spacing={2}>
      <Stack>
        <Typography variant="h4">
          {t('home.welcome.header', { firstName })}
        </Typography>
        <Typography>{t('home.welcome.subheader')}</Typography>
      </Stack>
      {/* <Stack padding={8}>
        <Box border={1}>TO DO (FITBIT)</Box>
      </Stack> */}
      <Stack spacing={2}>
        <Typography variant="h6">
          {t('home.welcome.exercise_header')}
        </Typography>
        <Typography>{t('home.welcome.exercise_subheader')}</Typography>
      </Stack>
    </Stack>
  );
}
